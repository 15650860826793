@import "common";

// bootstrap
@import "bootstrap/scss/modal";

.modal-content {
	background-color: $white;
	color: $primary;
	border-radius: 1.5rem;

	.modal-body {
		padding: 0;
		height: 100%;

		.btn-modal-close {
			position: absolute;
			right: 0;
			z-index: 9999;
		}
	}
}
