$enable-grid-classes: false !default;
$fa-font-path: '/assets/fonts' !default;
$brand-lavender: #7570EC;
$brand-teal: #00A4B2;
$brand-coral: #FF5B4E;
$light-grey: #eff1f2;
$grey: #747678;
$desktop-sidebar-width: 120px;
$desktov-nav-item-category-width: $desktop-sidebar-width;
$desktov-nav-item-category-height: 85px;
$desktop-nav-item-category-icon-width: 50px;
$desktop-nav-item-subcategories-width: 475px;
$desktop-nav-item-subcategories-bgcolor: #464646;
$desktop-nav-item-subcategories-color: white;
$desktop-nav-item-solution-color: white;
$desktop-nav-transition-speed: 300ms;
$mobile-header-height: 60px;
$desktop-splash-width: 600px;
$desktop-splash-height: 300px;
$mobile-splash-width: 100%;
$solution-card-width: 600px;
$solution-card-max-height-ratio: 0.4;
$sheet-width: calc(100% - #{$desktop-sidebar-width} - 4em);
$sheet-max-width: 750px;
$sheet-max-height: calc(100% - 4em);

// $enable-rounded: true !default;
// $enable-shadows: false !default;
// $enable-gradients: false !default;
// $enable-transitions: true !default;
// $enable-hover-media-query: false !default;
// $enable-grid-classes: true !default;
// $enable-print-styles: true !default;

// $body-color: #151717 !default;
// $body-bg: #151717 !default;
$primary: $brand-lavender !default;
$font-size-base: 1rem !default;

// $inverse-bg: #fff !default;
// $inverse-color: #151717 !default;
// $font-family-sans-serif: 'kelson_lt', sans-serif !default;
// $nav-link-height: 4.625rem !default;
// $navbar-brand-padding-y: .25rem !default;
// $navbar-toggler-padding-x: .75rem !default;
// $navbar-toggler-padding-y: .25rem !default;
// $headings-font-family: 'kelson_bd' !default;
// $headings-font-weight: normal !default;
// $jumbotron-bg: $body-bg !default;
// $jumbotron-padding: 4rem !default;
// $input-btn-border-width: 3px !default;
// $btn-border-radius: 20px !default;

$border-radius: 0 !default; // 0.25rem
$input-height: 3rem !default;
$input-padding-x: 1.125rem !default;
$input-border-radius: $border-radius !default;
$btn-border-width: 1px;
$btn-border-radius: 1.5rem !default;
$input-btn-focus-width: 0 !default;
$btn-active-box-shadow: none !default;
$input-btn-focus-box-shadow: none !default;
$btn-font-size: 0.75rem !default;
$btn-padding-y: 0.375rem !default;
$btn-padding-x: 0.75rem !default;
$modal-content-color: #fff !default;
$modal-content-bg: $grey !default;
$modal-content-border-color: transparent !default;
$modal-backdrop-opacity: 0.75 !default;
$modal-content-border-radius: 0 !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-padding-x: 0.75rem !default;
$hamburger-padding-y: 0.45rem !default;
$hamburger-layer-color: $primary !default;
$hamburger-types: (slider) !default;
$ng-select-height: $input-height !default;
$ng-select-border-radius: $input-border-radius !default;
$ng-select-border: #fff !default;
$ng-select-primary-text: $brand-lavender !default;
$ng-select-highlight: rgba($brand-lavender, 0.5) !default;
$ng-select-placeholder: $brand-lavender !default;
$ng-select-value-padding-left: $input-padding-x !default;
$ng-select-box-shadow: none !default;
$ng-select-value-font-size: 1rem !default;
