html {
	scroll-behavior: smooth;
	font-size: 16px;
	min-height: -webkit-fill-available;
}

body {
	font-family: Mont, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
}

a[nohref] {
	cursor: pointer;
}

h1 {
	font-size: 1.5rem;
	font-weight: 800;
}

.description {
	p {
		margin-bottom: 0.5rem;
	}

	li > p:last-child {
		margin-bottom: 0;
	}
}

.hide-ui {
	page-home {
		> vv-splash,
		> vv-solutions,
		> vv-technologies,
		> .buttons {
			display: none !important;
		}

		> products-nav {
			pointer-events: none;
			opacity: 0.6;
			filter: blur(5px);
		}
	}
}

.show-gui {
	#gui-panel {
		display: block !important;
	}
}

#material-canvas-debug-container {
	display: none;
}

.debug-canvas {
	~ #material-canvas-debug-container {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform-origin: top right;
		transform: scale(0.5);

		.material-canvas-debug {
			border: 1px solid red;
		}
	}
}
