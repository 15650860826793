/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 7, 2020 */

@font-face {
	font-family: Mont;
	src: url('/assets/fonts/Mont-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: Mont;
	src: url('/assets/fonts/Mont-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Mont;
	src: url('/assets/fonts/Mont-Bold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: Mont;
	src: url('/assets/fonts/Mont-Heavy.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
}
