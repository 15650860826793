@use 'sass:color';

.btn {
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	border: $btn-border-width solid transparent;

	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $line-height-base, $btn-border-radius);

	@include hover {
		text-decoration: none;
	}

	&:focus,
	&.focus {
		outline: 0;
		box-shadow: none;
	}

	&.disabled,
	&:disabled,
	fieldset[disabled] & {
		box-shadow: none;
		pointer-events: none;
	}

	&.btn-lavender {
		@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $line-height-base, $btn-border-radius);

		background-color: $brand-lavender;
		color: white;

		&.disabled,
		&:disabled,
		fieldset[disabled] & {
			color: color.adjust($brand-lavender, $lightness: 10%);
		}
	}

	&.btn-black {
		@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $line-height-base, $btn-border-radius);

		background-color: black;
		color: white;

		&.disabled,
		&:disabled,
		fieldset[disabled] & {
			color: color.adjust(white, $lightness: 10%);
		}
	}

	&.btn-white {
		@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $line-height-base, $btn-border-radius);

		background-color: white;
		color: black;

		&.disabled,
		&:disabled,
		fieldset[disabled] & {
			color: color.adjust(black, $lightness: 70%);
		}
	}

	&.btn-bordered {
		@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $line-height-base, $border-radius);

		color: $brand-lavender;
		background-color: transparent;
		border: $btn-border-width solid $brand-lavender;
	}

	&.btn-close {
		@include button-size(0, 0, $btn-font-size, $line-height-base, 0);

		background-color: transparent;
		border: none;
	}

	&.btn-link {
		@include button-size(0, 0, $btn-font-size, $line-height-base, 0);

		color: inherit;
		background-color: transparent;
		border: none;
	}

	&.btn-link-lavender {
		@include button-size(0, 0, $btn-font-size, $line-height-base, 0);

		color: $brand-lavender;
		background-color: transparent;
		border: none;
	}

	$class-btn-icon-prefix: btn-icon;
	$btn-icon-size: 1.5em;

	&[class*=" #{$class-btn-icon-prefix}-"] {
		position: relative;
		padding-left: calc(#{$btn-padding-x} + #{$btn-icon-size} + 0.5em);
		background-repeat: no-repeat;
		background-size: $btn-icon-size $btn-icon-size;
		background-position: $btn-padding-x 50%;
	}

	&.#{$class-btn-icon-prefix} {
		&-heart-outline {
			background-image: url("/assets/img/icon-heart-outline.svg");
		}

		&-heart {
			background-image: url("/assets/img/icon-heart.svg");
		}
	}

	> fa-icon {
		display: inline-block;
		width: 2em;
	}
}

.btn-group {
	display: block;
	margin: 0.5em 0;
	text-align: center;
}

span.btn-group {
	display: inline-block;
}
